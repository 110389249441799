import React, {useState, useEffect} from 'react'
import {graphql,navigate} from "gatsby"

const Home = ({data}) => {
    const [loc, setLocationName] = useState('')
    useEffect(() => {
        setLocationName(window.location.pathname)
        if (loc.includes('cn')) {
            navigate(`/cn/?v=${data.gitCommit.hash}`)
        } else {
            navigate(`/ja/?v=${data.gitCommit.hash}`)
        }
    }, [])
    return <div/>
}
export const query = graphql`
  query {
    gitCommit(latest: { eq: true }) {
      hash
    }
  }
`
export default Home